<template>
  <Modal @close="resetExpireReason" :visible="!!expireReason">
    <h5 slot="header" class="modal-title" id="loggedOutModalLabel">{{$t('js.modal.logged_out.header')}}</h5>
    <div slot="body" v-html="expireReason ? $t(`js.expire_reasons.${expireReason}`) : $t('js.modal.logged_out.body_html')">
    </div>
  </Modal>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import Modal from "./Modal";
  export default {
    name: "LoggedOutModal",
    components: {Modal},
    computed: {
      ...mapState(['voting']),
      expireReason(){
        return this.voting.expireReason
      }
    },
    methods: {
      ...mapMutations(['resetExpireReason'])
    }
  }
</script>