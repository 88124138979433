export default class SplitState {
    constructor(ballot, representedVoters) {
        this.ballot = ballot;
        this.selected = [];
        this.representedVoters = representedVoters;
        this.blankSelected = 0;
        this.errors = this.validate(); // vue automatically recalculates this when selected changes
    }

    effectiveWeight(){
        return Math.max(this.representedVoters - this.blankSelected, 0);
    }

    effectiveMinimum(){
        return this.ballot.minimumVotes * this.effectiveWeight()
    }

    effectiveMaximum(){
        return this.ballot.maximumVotes * this.effectiveWeight()
    }

    validate(){ // allow for blank vote? should that be an option on its own?
        let errors = [];

        if(Math.max(...this.selected.map(option => option.weight + this.blankSelected)) > this.representedVoters){
            errors.push({path: 'js.error.split.too_many_votes_on_one_option'})
        }

        if(this.appliedWeight() > this.effectiveMaximum()){
            errors.push({path:'js.error.split.too_many_votes_overall', values:{maximum: this.effectiveMaximum()}})
        }

        return errors;
    }

    complete(){
        if(this.validate().length === 0 && this.appliedWeight() >= this.effectiveMinimum()){
            return true
        }
        return false
    }

    availableWeight(){
        return this.effectiveMaximum()
    }

    appliedWeight(){
        return this.selected.reduce((sum,option)=> {
            return sum + option.weight
        }, 0);
    }

    extract(){
        if(this.validate().length === 0) // no errors
        return {
            ballot_id: this.ballot.id,
            votes: [
                ...this.selected
                .filter(weightedOption => weightedOption.weight > 0)
                .map(option => {
                    return {handle: option.handle, weight: option.weight}
                }),
                ...(this.blankSelected > 0 ? [{handle: 'blank', weight: this.blankSelected}] : [])
            ]
        }
    }
}