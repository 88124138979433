// Rails default includes
import './shared/log-override'
import Rails from '@rails/ujs'
import 'bootstrap'
import '@fortawesome/fontawesome-free'

const images = require.context('../images', true)

import Vue from 'vue'
import ActionCableVue from 'actioncable-vue'
import ElectionApp from '../components/frontend/voting/App.vue'
import SignUpApp from '../components/frontend/sign_up/App.vue'

import signUpStore from './frontend/signUpStore'
import signUpSession from "./frontend/signUpSession";
import store from './frontend/store'
import votingModule from "./frontend/votingModule";
import i18n from "./shared/i18n";
import createPersistedState from 'vuex-persistedstate'
import bsTooltip from './shared/bs-tooltips' // Instantiate the code


import '../stylesheets/frontend.scss'
import $ from "jquery"

Rails.start()

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'all',
  store
})


// Create our persisted state with paths for what to save and where to save it.
const storedState = createPersistedState({
  storage: window.sessionStorage,
  paths: ['voting', 'currentLocale', 'visibleTab']
})


// unhandled rejections (we use this as a global event handler)
window.onunhandledrejection = event => {
  let error = event.reason;
  if(!error.response){
    // display connection warning
    store.commit('connectionFailed')
    event.preventDefault()
  } else if(error.response.status === 401 && [1, 2, 3].includes(error.response.data.code)) { // [1: sessionExpired, 2: sessionNotFound, 3: voterNotFound]
    // logout voter
    store.dispatch('destroySession')
    event.preventDefault()
  }
}

// Register voting module
store.registerModule('voting', votingModule)
signUpStore.registerModule('session', signUpSession)

// Run storedState with store as input after the voting module is registered
// IMPORTANT for it to NOT override voting module on page refresh
storedState(store)
storedState(signUpStore)

document.addEventListener('DOMContentLoaded', () => {

  let electionApp = document.getElementById('election-app')
  if( electionApp ){
    let electionTitle = JSON.parse(electionApp.getAttribute('data-title'))
    let electionDescription = JSON.parse(electionApp.getAttribute('data-description'))
    let electionLogo = electionApp.getAttribute('data-logo')
    let electionId = electionApp.getAttribute('data-id')
    let electionUrl = electionApp.getAttribute('data-url')
    let electionLocales = JSON.parse(electionApp.getAttribute('data-locales'))
    let electionCodeTexts = JSON.parse(electionApp.getAttribute('data-electionCodeTexts'))
    let partnerBranding = JSON.parse(electionApp.getAttribute('data-partnerBranding'))
    let organisationBranding = JSON.parse(electionApp.getAttribute('data-organisationBranding'))

    store.commit('setPartnerBranding', partnerBranding)
    store.commit('setOrganisationBranding', organisationBranding)
    store.commit('setElectionUrl', { electionUrl })
    store.commit('setLocales', electionLocales)
    store.commit('setElection', {id: electionId, title: electionTitle, description: electionDescription, theme: {logo: electionLogo}, userModules: {}, electionCodeTexts})

    i18n.locale = store.state.currentLocale

    const app = new Vue({
      store,
      el: electionApp,
      i18n,
      render: h => h(ElectionApp, {
      })
    })
  }

  let signUpApp = document.getElementById('sign-up-app')
  if( signUpApp ){
    let election = JSON.parse(signUpApp.getAttribute('data-election'))
    let electionUrl = signUpApp.getAttribute('data-url')
    let electionLocales = JSON.parse(signUpApp.getAttribute('data-locales'))
    let partnerBranding = JSON.parse(signUpApp.getAttribute('data-partnerBranding'))
    let organisationBranding = JSON.parse(signUpApp.getAttribute('data-organisationBranding'))

    signUpStore.commit('setPartnerBranding', partnerBranding)
    signUpStore.commit('setOrganisationBranding', organisationBranding)
    signUpStore.commit('setElectionUrl', { electionUrl })
    signUpStore.commit('setLocales', electionLocales)
    signUpStore.commit('setElection', election)

    i18n.locale = signUpStore.state.currentLocale

    const app = new Vue({
      store: signUpStore,
      el: signUpApp,
      i18n,
      render: h => h(SignUpApp, {
      })
    })
  }

})

$(function () {
  $('#compatibilityModal').modal('show')
})

$(function(){
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });
})
