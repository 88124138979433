<template>
  <div class="option p-3 mb-3 border">
    <div class="d-flex">
      <img v-if="option.image" :src="option.image" class="img-fluid mr-3" style="max-height: 4em; max-width: 4em;">
      <span class="h5 mb-0">{{optionTitle}}</span>
      <svg @click="toggleSelection" @keydown.space.prevent="toggleSelection" tabindex="1"
            class="option-checkbox" :class="{checked}"
            role="checkbox" :aria-checked="checked"
            xmlns="http://www.w3.org/2000/svg">
        <text v-if="ranked" x="50%" y="75%">{{rank}}</text>
        <g v-else stroke-linecap="round" stroke-width="3px">
          <line x1="25%" y1="25%" x2="75%" y2="75%"/>
          <line x1="25%" y1="75%" x2="75%" y2="25%"/>
        </g>
      </svg>
      <div class="checkbox-clearfix"></div>
    </div>
    <div v-if="optionDescription" v-html="optionDescription" class="mt-2"></div>

  </div>
</template>

<script>
  import {mapGetters} from "vuex"

  export default {
    props: {
      option: {
        type: Object,
        required: true
      },
      value: {
        type: Array,
        required: true
      },
      ranked: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      toggleSelection(){
        if(this.checked){
          this.selected.splice(this.selected.indexOf(this.option.handle), 1)
        } else {
          this.selected.push(this.option.handle)
        }
      }
    },
    computed: {
      ...mapGetters(['firstAvailableLocale']),
      optionTitle(){
        return this.option.title[this.firstAvailableLocale] || Object.values(this.option.title)[0] || ""
      },
      optionDescription(){
        return this.option.description[this.firstAvailableLocale] || ""
      },
      selected: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },
      checked(){
        return this.selected.includes(this.option.handle)
      },
      rank(){
        return this.ranked ? 1 + this.selected.indexOf(this.option.handle) : null
      }
    }
  }
</script>
