import Vue from 'vue'
import Vuex from 'vuex';
import apiClient from "./apiClient";
import timingModule from "./timingModule";
import { i18n } from "../shared/i18n"
import debounce from 'lodash.debounce'

Vue.use(Vuex)


export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    timing: timingModule
  },
  state: {
    readonly: false,
    // Fixed for election
    electionUrl: null,
    postsUrl: null,

    partnerBranding: {
      logo: null,
      link: null
    },

    organisationBranding: {
      name: null,
      logo: null
    },

    // This value will get updated when a
    // new election json is fetched from server
    election: null,
    slides: [],
    posts: [],
    activeSlideId: null,
    locales: [],
    currentLocale: i18n.locale,
    chatUser: {
      id: null,
      type: null,
      name: null
    },

    activeHighlight: null,
    visibleTab: 'Presentation',

    // Used for backend Live
    postToHighlight: null,
    postToAddToBallot: null,

    // Used for determining which way to animate slides
    slideOffset: 0,

    // Used to determine if the server is available
    lostConnectionToServer: false,

    lostConnectionToWebsocket: false,

    //
    voterCounts: {
      totalVoters: 0,
      activeVoters: 0,
      totalEligibles: 0,
      activeEligibles: 0,
      activeEligiblesOrVoted: 0,
      totalEligiblesWeight: 0,
      activeEligiblesWeight: 0,
      activeEligiblesOrVotedWeight: 0,
      voted: 0,
      votedWeight: 0
    },

    // Overall theme color
    theme: 'light',

    // Notifications
    toasts: [],
    toastId: 0
  },
  getters:{
    activeSlide(state){
      if(state.slides === null) return null;
      return state.slides.find(slide => slide.id === state.activeSlideId)
    },
    firstAvailableLocale(state){
      return state.locales.includes(i18n.locale) ? i18n.locale : state.locales[0]
    }
  },
  mutations: {
    setPartnerBranding(state, partnerBranding){
      state.partnerBranding = partnerBranding
    },
    setOrganisationBranding(state, organisationBranding){
      state.organisationBranding = organisationBranding
    },
    setElectionUrl(state, { electionUrl }){
      state.electionUrl = electionUrl
      state.postsUrl = electionUrl.replace('/observe','')+'/posts'
    },
    setElection(state, election){
      state.election = election
    },
    setLocales(state, locales){
      state.locales = locales
    },
    setSlides(state, slides){
      state.slides = slides
    },
    setPosts(state, posts){
      let unreadIds = state.posts.filter(post => post.unread === true).map(post => post.id)
      state.posts = posts
      state.posts.forEach(post => unreadIds.includes(post.id) ? post.unread = true : null)
    },
    setCounts(state, voterCounts){
      state.voterCounts = Object.assign(state.voterCounts, voterCounts)
    },
    setChatUser(state, chatUser){
      state.chatUser = chatUser
    },
    setPostToHighlight(state, post){
      if(post === null)
        return state.postToHighlight = null

      state.postToHighlight = Object.assign({}, post)
    },
    setPostToAddToBallot(state, post){
      if(post === null)
        return state.postToAddToBallot = null

      state.postToAddToBallot = Object.assign({}, post)
    },
    setActiveHighlight(state, highlight){
      state.activeHighlight = highlight
    },
    setTheme(state, theme) {
      state.theme = theme
    },
    goToSlide(state, id){
      let newSlide = state.slides.find(slide => slide.id === id);
      if(!newSlide) return state.activeSlideId = null
      let oldSlide = state.slides.find(slide => slide.id === state.activeSlideId)
      state.slideOffset = oldSlide ? oldSlide.position - newSlide.position : newSlide.position;
      state.activeSlideId = id
    },
    createItem(state, {path, item}){
      switch(path.type){
        case 'slide':
          state.slides.push(item)
          break;
        case 'option':
          state.slides.find(slide => slide.id === path.slide_id).options.push(item)
          break;
        case 'post':
          if(state.visibleTab === 'Comment' && item.type === 'Comment') item.unread = false
          state.posts.push(item)
          break;
      }
    },
    updateItem(state, {path, changes}){
      let storeItem = null
      switch(path.type){
        case 'slide':
          storeItem = state.slides.find(slide => slide.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          if(changes.position !== undefined) {
            state.slides.sort((a, b) => a.position-b.position)
          }
          break;
        case 'option':
          storeItem = state.slides.find(slide => slide.id === path.slide_id).options.find(option => option.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          if(changes.position !== undefined) {
            state.slides.find(slide => slide.id === path.slide_id).options.sort((a, b) => a.position-b.position)
          }
          break;
        case 'post':
          storeItem = state.posts.find(post => post.id === path.id)
          storeItem = Object.assign(storeItem, changes)
          break;
        case 'highlight':
          state.activeHighlight = changes
          break;
      }
    },
    deleteItem(state, path){
      let itemIndex = null
      switch(path.type){
        case 'slide':
          itemIndex = state.slides.findIndex(slide => slide.id === path.id)
          if( ~ itemIndex ) state.slides.splice(itemIndex, 1)
          break
        case 'option':
          let slideItem = state.slides.find(slide => slide.id === path.slide_id)
          if( ! slideItem ) break
          itemIndex = slideItem.options.findIndex(option => option.id === path.id)
          if( ~ itemIndex ) slideItem.options.splice(itemIndex, 1)
          break
        case 'post':
          itemIndex = state.posts.findIndex(post => post.id === path.id)
          if( ~ itemIndex ) state.posts.splice(itemIndex, 1)
          break
      }
    },
    markAllPostsRead(state, type){
      let posts = state.posts.filter(p => p.type === type)
      posts.forEach(p => p.unread = false)
    },
    markPostRead(state, id){
      let post = state.posts.find(post => post.id === id)
      if(post) post.unread = false
    },
    setBallotState(state, {ballotId, ballotState}){
      let ballot = state.slides[state.slides.findIndex(slide => slide.id === ballotId)]
      ballot.state = ballotState
    },
    setResult(state, {ballotId, result}){
      let ballot = state.slides[state.slides.findIndex(slide => slide.id === ballotId)]
      ballot.result = result
    },
    resetBallot(state, ballotId){
      let ballot = state.slides[state.slides.findIndex(slide => slide.id === ballotId)]
      if(state.voting){
        let votedOn = state.voting.currentVoter.votedOn
        votedOn.splice(votedOn.indexOf(ballotId), 1)
      }
      ballot.result = null
      ballot.voteCount = 0
      ballot.votesCombinedWeight = 0
    },
    closeBallot(state, {ballotId, time}){
      let ballot = state.slides.find(slide => slide.id === state.activeSlideId)
      if(ballot.id !== ballotId) return

      ballot.disableAt = time
    },
    setProgress(state, { ballotId, progress }){
      let ballot = state.slides.find(slide => slide.id === state.activeSlideId)
      if( ballot.id !== ballotId )
        return

      ballot = Object.assign(ballot, progress)
    },
    setVisibleTab(state, tab){
      state.visibleTab = tab
    },
    connectionFailed(state) {
      state.lostConnectionToServer = true
    },
    connectionToWebsocketFailed(state) {
      state.lostConnectionToWebsocket = true
    },
    connectionToWebsocketRestored(state) {
      state.lostConnectionToWebsocket = false
    },
    connectionSucceeded(state){
      state.lostConnectionToServer = false
    },
    setLocale(state, locale){
      if(i18n.availableLocales.includes(locale)){
        state.currentLocale = locale
        i18n.locale = locale
      }
    },
    showToast(state, {header, body, classes, duration = null}){
      state.toastId += 1
      let index = state.toasts.findIndex(toast => toast.id === state.toastId)
      if(index !== -1) state.toasts.splice(index, 1)
      let toast = {
        id: state.toastId,
        header,
        body,
        classes
      }
      state.toasts.push(toast)
      if(duration){
        setTimeout(() => { this.commit('hideToast', toast) }, duration*1000)
      }
    },
    hideToast(state, toast){
      let index = state.toasts.indexOf(toast)
      if(index !== -1)
        state.toasts.splice(index, 1)
    },
    setReadonly(state, readonly){
      state.readonly = readonly
    }
  },
  actions: {
    updateStatus({commit, state, dispatch}, live = false){
      // app#status requires a voter session, observer#status does not, either can be accessed from this action
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      let url = live ? `${state.electionUrl}/live_status` : `${state.electionUrl}/status`
      return apiClient.get(url, config).then(res => {
        let { election, slides, voter, activeSlide, voterCounts, activeHighlight } = res.data

        if(state.voting) commit('updateVoter', voter)

        commit('setElection', election)
        if(activeSlide) {
          let activeIndex = slides.findIndex(slide => slide.id === activeSlide.id)
          slides[activeIndex] = { ...slides[activeIndex], ...activeSlide}
        }

        commit('setSlides', slides)
        commit('setCounts', voterCounts)
        commit('setActiveHighlight', activeHighlight)

        if(activeSlide)
          commit('goToSlide', activeSlide.id)
        else
          commit('goToSlide', null)


        console.log('election updated')
      })
    },
    updatePosts({commit, state}){
      // app#status requires a voter session, observer#status does not, either can be accessed from this action
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.get(`${state.postsUrl}`, config).then(res => {
        let {posts, chatUser} = res.data

        commit('setPosts', posts)
        commit('setChatUser', chatUser)

        console.log('posts updated')
      })
    },
    submitPost({dispatch, state}, post){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.post(state.postsUrl, post, config)
    },
    submitHighlight({dispatch, state}, highlight){
      if(state.voting)
        return

      return apiClient.post(state.electionUrl.replace('/observe','')+"/highlight", highlight)
    },
    editPost({dispatch, state}, post){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.put(state.postsUrl+"/"+post.id, post, config)
    },
    deletePost({dispatch, state}, postId){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.delete(state.postsUrl+"/"+postId, config)
    },
    restorePost({dispatch, state}, postId){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid
        }
      } : {};
      return apiClient.post(state.postsUrl+"/"+postId+"/restore", {},config)
    },
    fetchResult({commit,state}, ballotId){
      let config = state.voting ? {
        params: {
          signature: state.voting.signature,
          voter_session_uuid: state.voting.voterSessionUuid,
          ballot_id: ballotId
        }
      } : { params: {ballot_id: ballotId} };
      apiClient.get(state.electionUrl+"/result", config).then(res => {
        if(res.status === 200) {
          commit('setResult', {ballotId: ballotId, result: res.data})
        }
      })
    },
    fetchLiveResult({commit,state}, ballotId){
      apiClient.get(state.electionUrl+"/live_result", {params: {ballot_id: ballotId}}).then(res => {
        if(res.status === 200) {
          commit('setResult', {ballotId: ballotId, result: res.data})
        }
      })
    },
    checkConnection({commit, state}){
      apiClient.get(state.electionUrl, {timeout: 5000}).then(_ =>{
        commit('connectionSucceeded') // we got a response from the server, so not gone anymore
      }).catch(arg =>{
        if(arg.response) {
          commit('connectionSucceeded')  // we got an error response, which means we could make a connection
        }
      })
    },
    checkWebsocketConnection({commit, state}, cable = null) {
      let webSocket = cable ? cable : this.$cable
      if (!webSocket) return
      let isDisconnected = webSocket._cable.connection.disconnected
      if (state.lostConnectionToWebsocket && !isDisconnected) {
        commit('connectionToWebsocketRestored')
      } else if (!state.lostConnectionToWebsocket && isDisconnected) {
        commit('connectionToWebsocketFailed')
      }
    },
    // Asks the server to force broadcast update counts.
    // This is done to ensure that the last count event is sent out.
    // Otherwise it might have been suppressed by the throttler functionality.
    // If the `force` parameter is set to true, it means that the event was emitted by
    // this method, and an update should be prevented to not create an everlasting loop.
    ensureLastUpdateCounts: debounce(({state}, force) => {
      if( force ) return;
      apiClient.put(`${state.electionUrl}/ensure_update_counts`)
    }, 3000)
  }
})
