
// Set the name of the hidden property and the change event for visibility
var hidden, visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
}
const visibility = {
    isSupported() {
        return hidden !== undefined
    },
    visible: true
}

function handleVisibilityChange() {
    console.log(`page visibility changed to ${document[hidden]?'hidden':'visible'}`)
    visibility.visible = !document[hidden];
}

if (hidden !== undefined) {
    // Handle page visibility change
    console.log("binding visibility handler")
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
}
export default visibility