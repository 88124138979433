<template>
    <div>
        <div v-if="ballotStateIn(['open','countdown']) && canVote" class="px-3 px-md-5 py-1 bg-light" :class="{'bg-theme-danger': errors && showErrors}">
            <template v-if="!voted">
                <template v-if="ballot.ruleSet === 'split'">
                    <SplitBallotProgress :ballotState="sticky.ballotState" class="mx-n3 mx-md-n5 mt-n1 mb-1" />
                    <div v-if="errors && showErrors">
                        <span class="sub-lead" v-for="error in errors"> {{$t(error.path, error.values)}} </span>
                    </div>
                    <div class="d-flex align-items-center position-relative">
                      <div class="mr-2" @mouseover="showInfo = true" @mouseleave="showInfo = false">
                        <i class="fas fa-question-circle sub-lead"></i>
                      </div>
                      <transition name="fade">
                        <div class="popover bs-popover-top" role="tooltip" v-show="showInfo" style="bottom: 3.3em; top: unset; max-width: inherit">
                          <div class="arrow"></div>
                          <div class="popover-body">
                            <p v-text="$t('js.ballot_split.option_help_about_progress')" class="mb-0"></p>
                          </div>
                        </div>
                      </transition>
                      <p class="sub-lead mb-0 flex-grow-1">{{ $t('js.ballot_split.available_votes', {availableWeight: availableWeight, maximumPerOption: sticky.ballotState.effectiveWeight()}) }}</p>
                      <div class="ml-3 p-2 h6 mb-0 rounded bg-secondary text-dark">
                          {{selected + '/' + availableWeight}}
                      </div>
                    </div>
                </template>
                <template v-else>
                    <div v-if="errors">
                        <span class="sub-lead" v-for="error in errors"> {{$t(error.path, error.values)}} </span>
                    </div>
                    <div  class="d-flex justify-content-between flex-wrap">
                        <span class="sub-lead mr-2">{{crossCounter}}</span>
                        <span class="sub-lead">{{ $t('js.ballot.selected') }}: {{selected}}</span>
                    </div>
                </template>
            </template>

            <div v-else>
                <span class="sub-lead">{{ $t('js.ballot.vote_registered') }}</span>
            </div>
        </div>

        <div class="px-3 px-md-5 py-1 bg-theme" :class="{'progress-bar-striped progress-bar-animated': ballotStateIn('calculating_result')}">
            <template v-if="ballotStateIn(['open','countdown'])">
                <div class="d-flex d-sm-block justify-content-between align-items-center">
                    <span v-if="canVote" class="sub-lead">{{ $t('js.ballot.open') }}</span>
                    <span v-else class="sub-lead">{{ $t('js.ballot.open') }}<br> {{ $t('js.ballot.cant_vote')}}</span>
                    <span class="sub-lead float-sm-right ml-3 text-monospace pb-sm-0" style="height: 0px; padding-bottom: 20px">
                        <b v-if="ballot.disableAt" class="mb-0"><Countdown :end-time="ballot.disableAt" @timesUp="updateStatus()"/></b>
                    </span>
                </div>
            </template>
            <template v-else-if="ballotStateIn('calculating_result')">
                <p class="sub-lead mb-0">{{ $t('js.result.status.result_calculating') }}</p>
            </template>
            <template v-else-if="ballotStateIn('finished')">
                <p class="sub-lead mb-0">{{ $t('js.result.status.result_ready') }}</p>
            </template>
            <template v-else-if="ballotStateIn('new')">
                <span class="sub-lead">{{ $t('js.ballot.not_yet_open') }}</span>
            </template>
            <template v-else-if="ballotStateIn('closed')">
                <span class="sub-lead">{{ $t('js.ballot.has_closed') }}</span>
            </template>
        </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Countdown from "../shared/Countdown";
import SplitBallotProgress from "./SplitBallotProgress";

export default {
        name: "BallotStatus",
        props: {
            sticky: {
                type: Object,
                required: true
            }
        },
        data: () => ({
          showInfo: false
        }),
        components: {
          SplitBallotProgress,
            Countdown
        },
        computed: {
            ...mapState(['voting']),
            canVote(){
                return this.voting && this.voting.currentVoter.canVoteOn.includes(this.ballot.id)
            },
            crossCounter(){
              let counter = null
              if(this.ballot.ruleSet === 'ranked'){
                counter = this.ballot.minimumVotes === this.ballot.maximumVotes ?
                    this.$tc('js.ballot.rank_x_options', this.ballot.minimumVotes) :
                    this.$t('js.ballot.rank_between_x_and_y_options', {x: this.ballot.minimumVotes, y: this.ballot.maximumVotes})
              } else {
                counter = this.ballot.minimumVotes === this.ballot.maximumVotes ?
                    this.$tc('js.ballot.choose_x_options', this.ballot.minimumVotes) :
                    this.$t('js.ballot.choose_between_x_and_y_options', {x: this.ballot.minimumVotes, y: this.ballot.maximumVotes})
              }
              return counter
            },
            voted(){
                return this.sticky.voted
            },
            selected(){
                return this.sticky.selected
            },
            errors(){
                return this.sticky.errors
            },
            showErrors(){
                return this.errors.length > 0
            },
            availableWeight(){
                return this.sticky.availableWeight
            },
            ballot(){
                return this.sticky.ballot
            }
        },
        methods: {
            ...mapActions(['updateStatus']),
            ballotStateIn(ballotStates) {
                if(this.ballot){
                    if(Array.isArray(ballotStates)){
                        return ballotStates.includes(this.ballot.state)
                    } else {
                        return ballotStates === this.ballot.state
                    }
                }
                return false
            }
        }
    }
</script>