<template>
  <div class="option-result col-12" style="overflow-x: auto">
    <horizontal-bars v-if="chartType === 'horizontal_bars'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative"/>
    <vertical-bars v-else-if="chartType === 'vertical_bars'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" :style="{minWidth: `${Math.max(40, labelledCounts.length*2)}em`}"/>
    <pie-chart v-else-if="chartType === 'pie'" :theme="theme" :chart-data="chartData" :options="chartOptions" class="option-content w-100 position-relative" style="min-width: 40em"/>
  </div>
</template>
<script>
import HorizontalBars from "./charts/HorizontalBars"
import {mapGetters, mapState} from "vuex";
import VerticalBars from "./charts/VerticalBars";
import PieChart from "./charts/PieChart";

export default {
  name: 'NormalResultChart',
  components: {PieChart, VerticalBars, HorizontalBars},
  props: {
    result: {
      type: Object,
      required: true
    },
    ballot: {
      type: Object,
      required: true
    },
    theme: {
      type: String,
      default: 'light'
    },
    chartOptions: {
      type: Object,
      default: ()=>({})
    }
  },
  methods: {
    truncateLabel(label, maxLength=110, ending='…'){
      return label.length > maxLength ?
          label.slice(0,maxLength)+ending :
          label;
    }
  },
  computed:{
    ...mapGetters(['firstAvailableLocale']),
    ...mapState(['election']),
    chartType() {
      return this.ballot.resultChart
    },
    labelledCounts(){
      return this.result.resultData.optionSortedCounts.map(row => {
        let loptions = this.ballot.options
        let option = loptions.find(o => o.handle === row[0])
        return ({
          label: this.truncateLabel(option.title[this.firstAvailableLocale] || Object.values(option.title)[0] || 'missing translation'),
          count: row[1],
          elected: this.result.resultData.elected.includes(option.handle),
          tied: this.result.resultData.tied.includes(option.handle),
          disabled: this.result.resultData.disabledOptions.includes(option.handle)
        });
      })
    },
    chartData() {
      let colors = this.labelledCounts.map((count, index) => {
        if(count.elected){
          return `#d4edda`
        } else if(count.tied){
          return `#fff3cd`
        } else {
          return `#6c757d`
        }
      })
      return {
        labels: this.labelledCounts.map(count => {
          return count.label
        }),
        datasets: [{
          backgroundColor: colors,
          data: this.labelledCounts.map(count => {
            return count.count;
          })
        }]
      }
    }
  }
}
</script>