<template>
    <ol class="slide-indicators position-relative align-items-end mb-3" style="z-index: 2;">
        <li v-for="(slide, index) in slides" :class="{'active': slide.id === activeSlide.id}" class="shadow-lg">
            <div class="text-center">{{1+index}}</div>
        </li>
    </ol>
</template>

<script>
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "SlideIndicators",
        computed:{
            ...mapState(['slides']),
            ...mapGetters(['activeSlide'])
        }
    }
</script>

<style>
    .slide-indicators {
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
    }

    .slide-indicators .active {
        background-color: #000;
        color: white;
    }

    .slide-indicators li {
        box-sizing: content-box;
        flex: 0 1 auto;
        width: 3px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        background-color: #fff;
        overflow: hidden;
        transition: all .6s ease;
    }
    .slide-indicators li.active, .slide-indicators li:first-child, .slide-indicators li:last-child{
        height: 1.5rem;
        min-width: 1.5em;
        width: 1.5em;
    }
</style>