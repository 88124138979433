<template>
  <div class="row rounded shadow">
    <div class="col-lg-4 p-3 p-md-4 bg-white" v-if="election">
      <div v-if="organisationBranding.name" class="mb-3 mt-n3 mt-md-n4 mx-n3 mx-md-n4 p-1 bg-secondary text-center">
        <span class="sub-lead"><b>{{organisationBranding.name}}</b></span>
      </div>
      <LocaleSwitcher class="mb-3"/>
      <img v-if="election.theme.logo" :src="election.theme.logo" class="mb-3" style="max-height:100px;max-width:100%" />
      <div class="h3">{{ election.title[$i18n.locale] }}</div>
      <div class="lead" v-html="election.description[$i18n.locale]"></div>
    </div>
    <div class="col-lg-8 p-3 p-md-4 bg-theme d-flex flex-column justify-content-center">
      <h4>{{ $t('js.authenticate.sign_in_header') }}</h4>
      <form class="form" @submit.prevent="attemptLogin(electionCodes.map(ec=>electionCodeValues[ec.id]))" autocomplete="off">
        <div class="form-group" v-for="(electionCode, index) in electionCodes">
          <label :for="`election_code_${index}`" class="label">{{ electionCode.label[$i18n.locale] }}</label>
          <div class="input-group password-input-group">
            <input :id="`election_code_${index}`" :type="showPassword[electionCode.id] ? 'text' : 'password'" class="form-control"
                   v-model="electionCodeValues[electionCode.id]" autocomplete="off" :autofocus="index===0" value="">
            <div :aria-checked="showPassword[electionCode.id]" :aria-label="$t('js.authenticate.show_password')"
                 class="btn password-toggle" role="checkbox"
                 @click="showPassword[electionCode.id] = !showPassword[electionCode.id]">
              <span class="fas" :class="showPassword[electionCode.id] ? 'fa-eye' : 'fa-eye-slash'"></span>
            </div>
          </div>
          <small>{{electionCode.help[$i18n.locale]}}</small>
        </div>
        <transition name="fade" mode="out-in">
          <div class="p-3 mb-3 bg-theme-danger" v-if="error">
            <a class="float-right" @click="dismissError()" :aria-label="$t('js.actions.close')">
              <span class="fas fa-times"></span>
            </a>
            {{errorMessage}}
          </div>
        </transition>

        <div class="form-group text-right">
          <button type="submit" :disabled="!canLogin" class="btn btn-outline-contrast rounded-0"><span v-if="waitingForResponse" class="fas fa-spin fa-spinner"></span> {{ $t('js.authenticate.sign_in_button') }}</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import LocaleSwitcher from "../shared/LocaleSwitcher";

  export default {
    components: {LocaleSwitcher},
    data(){
      return {
        error: null,
        electionCodeValues: {},
        showPassword: {1: false, 2: false, 3: false},
        waitingForResponse: false
      }
    },
    methods: {
      ...mapActions(['authenticate']),
      async attemptLogin(electionCodes) {
        this.error = null

        //strip leading and trailing whitespace to protect against copy paste errors
        this.waitingForResponse = true
        this.error = await this.authenticate(electionCodes.map(e_code => e_code.trim()))
        this.waitingForResponse = false
      },
      dismissError(){
        this.error = null
      }
    },
    computed: {
      ...mapState(['election', 'organisationBranding']),
      errorMessage(){
        switch (this.error) {
          case 'login_failed_voter_not_found':
            return this.$t('js.authenticate.errors.login_failed_voter_not_found')
          case 'login_failed_demo_voter_in_prod':
            return this.$t('js.authenticate.errors.login_failed_voter_not_found')
          case 'login_failed_voter_disabled':
            return this.$t('js.authenticate.errors.login_failed_voter_disabled')
          case 'login_failed_sign_up_required':
            return this.$t('js.authenticate.errors.login_failed_sign_up_required')
          case 'login_failed_signature_invalid':
            return this.$t('js.authenticate.errors.login_failed_signature_invalid')
          default:
            return this.$t('js.authenticate.errors.login_failed')
        }
      },
      electionCodes(){
        return this.election.electionCodeTexts
      },
      canLogin(){
        return !this.waitingForResponse && this.electionCodes.every(code => {
              return this.electionCodeValues[code.id]
            }, this)
      }
    },
    mounted: function () {
      if( window.location.hash ){
        let segments = location.hash.slice(1).split(',')
        location.hash = ''
        let direct = false
        if(segments[0] === 'd') {
          segments = segments.slice(1)
          direct = true
        }
        let codes = new Map()
        for(let segment of segments){
          let match = segment.match(/^([123]):([a-zA-Z0-9_]+)$/)
          if(match !== null){
            let [_, num, inputValue] = match
            codes.set(num, inputValue)
          }
        }
        this.electionCodeValues = Array.from(codes.entries()).reduce((main, [key, value]) => ({...main, [key]: value}), {})
        if(direct) {
          this.attemptLogin([...codes.values()])
        }
      }
    }
  }
</script>