import sjcl from './sjcl'

export class VoteEncryption {
  encryptor;

  constructor(config) {
    this.encryptor = this.createEncryptor(config)
  }

  createEncryptor(encryptionConfig){
    switch (encryptionConfig.type) {
      case 'unencrypted':
        return text => text
      case 'aes':
        let publicKeyString = encryptionConfig.publicKey || '';

        let match = publicKeyString.match(/^\s*([0-9a-f]{64})\s*([0-9a-f]{64})\s*$/)
        if(!match) {
          throw 'js.error.invalid_public_key_format'
        }

        let x = sjcl.bn.fromBits(sjcl.codec.hex.toBits(match[1]))
        let y = sjcl.bn.fromBits(sjcl.codec.hex.toBits(match[2]))

        let publicPoint = new sjcl.ecc.point(sjcl.ecc.curves.k256, x, y)
        let publicKey = new sjcl.ecc.elGamal.publicKey(sjcl.ecc.curves.k256, publicPoint)

        return text => sjcl.encrypt(publicKey, text)
    }
  }
}