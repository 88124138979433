<template>
    <div class="mb-n3 mb-md-n5">
        <slot name="before">
            <!--content before sticky goes here (sticky will be stuck to bottom of viewport) -->
        </slot>
        <div v-show="stickies" class="sticky-bottom mt-3 mt-md-5 mx-n3 mx-md-n5 sticky-shadow" :class="{'shadow-sm': !isSticky}">
            <div v-for="sticky in stickies">
                <div v-if="sticky.type === 'ballotStatus'">
                    <BallotStatus :sticky="sticky"/>
                </div>
                <div v-else class="text-sm-center px-3 py-2 bg-theme" :class="sticky.class">
                    <span class="sub-lead">{{sticky.message}}</span>
                </div>
            </div>
        </div>
        <a id="sticky-anchor"></a>
        <slot name="after">
            <!-- content after sticky goes here (sticky will scroll out of view with the content in "before" slot -->
        </slot>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'
    import BallotStatus from "./BallotStatus";

    export default {
        name: "StickyAlerts",
        components: {
            BallotStatus
        },
        data() {
            return {
                subscribers: [],
                isSticky: false
            }
        },
        methods: {
            ...mapActions(['updateStatus']),
            stickyAlertsSubscribe(component) {
                this.subscribers.push(component)
            }
        },
        computed: {
            stickies() {
                let alerts = []
                this.subscribers.forEach(subscriber => subscriber.stickies.forEach(alert => alerts.push(alert)))
                return alerts
            }
        },
        provide: function () {
            return {
                stickyAlertsSubscribe: this.stickyAlertsSubscribe
            }
        },
        mounted: function () {
            let options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.0
            }
            let component = this;
            let observer = new IntersectionObserver(function (entries, observer) {
                component.isSticky = !entries[0].isIntersecting
            }, options).observe(document.querySelector('#sticky-anchor'));
        }
    }

</script>