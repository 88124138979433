<template>
  <Modal :visible="connectionLost" :closable="false">

    <h5 slot="header" class="modal-title" id="connectionIndicatorModalLabel">{{$t('js.connection_indicator.connection_lost')}}</h5>

    <p v-if="lostConnectionToInternet" slot="body">
      {{$t('js.connection_indicator.browser_down')}}
    </p>
    <p v-else slot="body">
      {{$t('js.connection_indicator.server_down')}}
    </p>
    <div slot="footer" v-if="lostConnectionToInternet" class="text-center">
      {{$t('js.connection_indicator.please_connect_your_device_to_the_internet')}}
    </div>
    <button slot="footer" v-else-if="lostConnectionToServer" type="button" class="btn btn-primary" @click="attemptReconnect" :disabled="attemptingReconnect">
      <span v-if="attemptingReconnect" class="fa fa-spin fa-spinner"></span>{{$t('js.connection_indicator.attempt_reconnect')}}
    </button>
  </Modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Modal from "../voting/modals/Modal";
export default {
  name: "ConnectionIndicator",
  components: {Modal},
  data() {
    return {
      lostConnectionToInternet: false,
      attemptingReconnect: false
    }
  },
  computed: {
    ...mapState(['lostConnectionToServer', 'lostConnectionToWebsocket']),
    connectionLost() {
      return this.lostConnectionToInternet || this.lostConnectionToServer || this.lostConnectionToWebsocket;
    }
  },
  methods: {
    ...mapActions(['checkConnection']),
    async attemptReconnect() {
      this.attemptingReconnect = true
      try {
        await this.checkConnection()
      } catch (e) {
        console.log(e)
      }
      this.attemptingReconnect = false;
    },
    setLostConnectTrue() {
      this.lostConnectionToInternet = true
    },
    setLostConnectFalse() {
      this.lostConnectionToInternet = false
    }
  },
  mounted() {
    window.addEventListener('offline', this.setLostConnectTrue);
    window.addEventListener('online', this.setLostConnectFalse);
  }
}
</script>