<template>
    <div class="ballot mt-3" :class="{'ballot-error': errors.length > 0 }" :key="ballotReload">
      <div class="h4" v-if="ballot.question">
        {{ ballot.question[$i18n.locale] }}
      </div>
      <template v-if="ballotStateIn('finished')">
        <Result v-if="result" :slide="ballot" />
      </template>
      <template v-else-if="ballotStateIn(['open','countdown'])">
        <div v-if="!ballot.anonymous" class="alert alert-info">
          <details>
            <summary class="h5 mb-0"><i class="fas fa-exclamation-circle"></i> {{ $t('js.ballot.anonymous_notice.header') }}</summary>
            <p class="mb-0">{{ $t('js.ballot.anonymous_notice.body') }} </p>
          </details>
        </div>
        <template v-if="canVote">
          <template v-if="voted">
            <div class="mb-3">
              <h4 class="text-success">{{ $t('js.ballot.vote_registered') }}</h4>
              <p>{{ $t('js.ballot.redo_notice') }}</p>
              <button class="btn btn-secondary" @click.prevent="setRedoVote(true)">{{ $t('js.ballot.redo_vote') }}</button>
              <hr>
              <BallotProgress :ballot="ballot" class="mb-1" />
            </div>
          </template>
          <template v-else>
            <div v-if="ballot.ruleSet === 'split'" class="position-relative mb-3" style="display: grid; grid-template-columns: 4em 1fr">
              <button type="button" @click="showInfo = !showInfo" @blur="showInfo = false" class="btn btn-link">
                <i class="fas fa-question-circle h2 m-auto"></i>
              </button>
              <div class="text-muted lead">
                {{ $tc('js.ballot.assigned_ballots', voting.currentVoter.weight) }} {{ballotRules}}<br>
                {{ crossCounter }}
              </div>
              <transition name="fade">
                <div class="popover bs-popover-bottom" role="tooltip" v-show="showInfo" style="top: 4.5em; max-width: inherit">
                  <div class="arrow" style="left: 1.2em"></div>
                  <h3 class="popover-header" v-text="$t('js.ballot_split.option_help_title')"></h3>
                  <div class="popover-body">
                    <p v-text="$t('js.ballot_split.option_help_about_ballot')"></p>
                    <p v-text="$t('js.ballot_split.option_help_about_blank')" class="mb-0"></p>
                  </div>
                </div>
              </transition>
            </div>
            <div v-else class="lead text-right text-muted">
              {{ $tc('js.ballot.your_vote_weight', ballot.disregardWeights ? 1 : voting.currentVoter.weight) }} <br>
              {{crossCounter}}
            </div>
            <div class="options">
              <template v-if="ballot.ruleSet === 'split'">
                <OptionNumber
                    v-for="option in ballot.options"
                    :key="option.handle"
                    :option="option"
                    :available-weight="state.availableWeight()-state.appliedWeight()"
                    :voterWeight="state.representedVoters-state.blankSelected"
                    :maximumVotes="ballot.maximumVotes"
                    v-model="state.selected"
                />
              </template>
              <template v-else>
                <OptionBoolean
                    v-for="option in ballot.options"
                    :key="option.handle"
                    :option="option"
                    v-model="state.selected"
                    :ranked="ballot.ruleSet === 'ranked'"
                />
              </template>
              <OptionBlank
                  v-if="ballot.blankOption === 'active_choice' || ballot.blankOption === 'implicit'"
                  v-show="ballot.blankOption === 'active_choice'"
                  :key="'blankOption'"
                  :ruleSet="ballot.ruleSet"
                  v-model="state.blankSelected"
                  :state="state"
              />
            </div>

            <div class="clearfix">
              <div class="float-right"  v-tooltip="''" :data-original-title="invalid ? $t('js.ballot.tooltips.ballot_state_incomplete') : null">
                <AsyncButton @click="attemptSubmitVotes" :disabled="invalid" :style="invalid ? {pointerEvents: 'none'} : null" class="btn btn-theme">
                  {{ $t('js.ballot.submit_vote')}}
                  <template #waiting>
                    <span><i class="fas fa-spin fa-spinner"></i> {{ $t('js.ballot.submitting_vote') }}</span>
                  </template>
                </AsyncButton>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div v-for="option in ballot.options" class="option p-3 mb-3 border">
            <span class="h5">{{ option.title[$i18n.locale] }}</span>
            <div v-html="option.description[$i18n.locale]"></div>
          </div>
          <BallotProgress :ballot="ballot" class="mb-1" />
        </template>
      </template>
      <div class="ballot-errors" v-if="error">
        <p class="bg-theme-danger p-3 m-3">{{this.submitErrorText}}</p>
      </div>
    </div>
</template>

<script>
  import {mapState, mapActions, mapMutations, mapGetters} from 'vuex'
  import Result from '../shared/Result'
  import Progress from '../../shared/Progress'
  import OptionBoolean from './OptionBoolean'
  import OptionNumber from './OptionNumber'
  import OptionBlank from './OptionBlank'
  import DefaultState from "../../../packs/frontend/states/DefaultState";
  import Countdown from "../shared/Countdown";
  import RankedState from "../../../packs/frontend/states/RankedState";
  import Option from "../shared/Option"
  import SplitState from "../../../packs/frontend/states/SplitState";
  import BallotProgress from "../shared/BallotProgress";
  import AsyncButton from "../../shared/AsyncButton"

  export default {
    inject: ['stickyAlertsSubscribe'],
    components: {
      AsyncButton,
      BallotProgress,
      Option,
      Countdown,
      OptionBoolean,
      OptionNumber,
      OptionBlank,
      Result,
      Progress
    },
    props: {
      ballot: Object
    },
    data(){
      return {
        state: this.newState(this.ballot),
        error: null,
        reloadBallot: false,
        showInfo: false
      }
    },
    computed: {
      ...mapState(['election','voting', 'voterCounts']),
      ...mapGetters(['activeSlide']),
      voted(){
        return !this.voting.redoVote && this.voting.currentVoter.votedOn.includes(this.ballot.id)
      },
      canVote(){
        return this.voting.currentVoter.canVoteOn.includes(this.ballot.id)
      },
      result(){
        return this.ballot.result
      },
      presentVoters(){
        return Math.max(this.voterCounts.activeEligiblesOrVoted, this.voteCount, 0)
      },
      voteCount(){
        if(this.ballot){
          return this.ballot.disregardWeights ? this.ballot.voteCount : this.ballot.votesCombinedWeight
        }
        return null
      },
      ballotReload(){
        return `${this.ballot.id} ${this.reloadBallot}`
      },
      errors(){
        return this.state.validate()
      },
      submitErrorText(){
        if(this.error === null) return ""
        switch (this.error) {
          case 'vote_rejected_ballot_closed':
            return this.$t('js.ballot.errors.vote_rejected_ballot_closed')
          case 'vote_rejected_already_voted':
            return this.$t('js.ballot.errors.vote_rejected_already_voted')
          case 'vote_rejected_ballot_association':
            return this.$t('js.ballot.errors.vote_rejected_ballot_association')
          case 'vote_rejected_unknown_ballot_rule_set':
            return this.$t('js.ballot.errors.vote_rejected_unknown_ballot_rule_set')
          case 'vote_rejected_error_unknown':
            return this.$t('js.ballot.errors.vote_rejected_error_unknown')
          default:
            return this.$t('js.ballot.errors.vote_rejected_error_unknown')
        }
      },
      invalid() {
        return !this.state.complete()
      },
      blankOption(){
        return this.ballot.blankOption
      },
      ballotState(){
        return this.ballot.state
      },
      currentVoter(){
        return this.voting.currentVoter
      },
      crossCounter(){
        let counter = null
        if(this.ballot.ruleSet === 'ranked'){
          counter = this.ballot.minimumVotes === this.ballot.maximumVotes ?
              this.$tc('js.ballot.rank_x_options', this.ballot.minimumVotes) :
              this.$t('js.ballot.rank_between_x_and_y_options', {x: this.ballot.minimumVotes, y: this.ballot.maximumVotes})
        } else if(this.ballot.ruleSet === 'split') {
          counter = this.ballot.minimumVotes === this.ballot.maximumVotes ?
              this.$tc('js.ballot.choose_x_options_split', this.state.effectiveMinimum(), {maximumPerOption: this.state.effectiveWeight()}) :
              this.$t('js.ballot.choose_between_x_and_y_options_split', {x: this.state.effectiveMinimum(), y: this.state.effectiveMaximum(), maximumPerOption: this.state.effectiveWeight()})
        } else {
          counter = this.ballot.minimumVotes === this.ballot.maximumVotes ?
              this.$tc('js.ballot.choose_x_options', this.ballot.minimumVotes) :
              this.$t('js.ballot.choose_between_x_and_y_options', {x: this.ballot.minimumVotes, y: this.ballot.maximumVotes})
        }
        return counter
      },
      ballotRules(){
        let rules = null
        if(this.ballot.ruleSet === 'split') {
          rules = this.ballot.minimumVotes === this.ballot.maximumVotes ?
              this.$tc('js.ballot.ballot_votes', this.ballot.minimumVotes) :
              this.$tc('js.ballot.ballot_min_max', this.voting.currentVoter.weight, {x: this.ballot.minimumVotes, y: this.ballot.maximumVotes})
        }
        return rules
      },
      stickies(){
        let stickies = []
        if(this.ballot){
          if(this.ballot.ruleSet === 'split'){
            stickies.push({
              type: 'ballotStatus',
              ballot: this.ballot,
              selected: this.state.appliedWeight(),
              ballotState: this.state,
              voted: this.voted,
              availableWeight: this.state.availableWeight(),
              voterWeight: this.voting.currentVoter.weight,
              errors: this.errors
            })
          }else{
            stickies.push({
              type: 'ballotStatus',
              ballot: this.ballot,
              selected: this.state.selected.length,
              voted: this.voted,
              errors: this.errors
            })
          }

        }

        return stickies
      }
    },
    watch: {
      ballotState: function(newState, oldState){
        if(newState === 'new'){
          this.state = this.newState(this.ballot)
          this.error = null
        }
      },
      currentVoter: function(newVoter, oldVoter){
        if(this.ballot.ruleSet !== 'split')
          return

        if(!this.isEqual(newVoter, oldVoter)){
          this.state = this.newState(this.ballot) // Instantiate new state for logic
          this.reloadBallot = !this.reloadBallot // Rerender ballot to remove any inputs
        }
      },
      blankOption: function(newVal, oldVal){
        this.state = this.newState(this.ballot) // Instantiate new state for logic
        this.reloadBallot = !this.reloadBallot // Rerender ballot to remove any inputs and redraw potential blank vote
      }
    },
    methods: {
      ...mapActions(['submitVotes', 'updateStatus']),
      ...mapMutations(['setRedoVote']),
      ballotStateIn(ballotStates) {
        if(this.ballot){
          if(Array.isArray(ballotStates)){
            return ballotStates.includes(this.ballot.state)
          } else {
            return ballotStates === this.ballot.state
          }
        }
        return false
      },
      async attemptSubmitVotes(){
        if( this.state.complete() ){
          this.error = await this.submitVotes(this.state.extract())
        }
      },
      newState(ballot) {
        if(!this.voting) return null
        if( ballot.ruleSet === 'default' ){
          return new DefaultState(ballot)
        } else if( ballot.ruleSet === 'ranked' ){
          return new RankedState(ballot)
        } else if( ballot.ruleSet === 'split' ){
          return new SplitState(ballot, this.voting.currentVoter.weight)
        }

        throw new Error('Ballot rule_set not supported: ' + ballot.ruleSet)
      },
      isEqual(object1, object2){
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length) {
          return false;
        }

        for (let key of keys1) {
          // To string because equality checking in JS is ***.
          if (object1[key].toString() !== object2[key].toString()) {
            return false;
          }
        }

        return true;
      }
    },
    beforeMount() {
      this.stickyAlertsSubscribe(this)
      console.log("new ballot");
      if(this.ballot) this.state = this.newState(this.ballot)
    }
  }
</script>
