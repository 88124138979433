<template>
  <div class="progress rounded-0 d-flex justify-content-between position-relative" style="height: 6px; overflow: visible;">
    <div class="progress-bar hover-fatten" :class="relativeApplied < relativeMinimum ? 'bg-theme-danger' : 'bg-theme'"
         v-tooltip="$t('js.ballot_split.tooltips.chosen', {count: ballotState.appliedWeight()})"
         :style="{width: `${relativeApplied}%`}"
         :key="`chosen=${ballotState.appliedWeight()}`"
    >
    </div>
    <div class="progress-bar bg-secondary hover-fatten" style="flex: auto"
         v-tooltip="$t('js.ballot_split.tooltips.remaining', {count: ballotState.effectiveMaximum() - ballotState.appliedWeight()})"
         :key="`remaining=${ballotState.effectiveMaximum() - ballotState.appliedWeight()}`"
    ></div>
    <div class="progress-bar hover-fatten" style="background-color: #888888" :style="{width: `${relativeBlank}%`}"
         v-tooltip="$t('js.ballot_split.tooltips.blank_ballots', {count: ballotState.blankSelected})"
         :key="`blank_ballots=${ballotState.blankSelected}`"
    >
    </div>
    <div class="minimum-indicator" v-tooltip="$t('js.ballot_split.tooltips.minimum', {count: ballotState.effectiveMinimum()})"
         :key="`minimum=${ballotState.effectiveMinimum()}`"
         :style="{left: `calc(${relativeMinimum}% - 8px)`}"></div>
  </div>
</template>
<script>
import SplitState from "../../../packs/frontend/states/SplitState";

export default {
  name: 'SplitBallotProgress',
  props: {
    ballotState: {
      type: SplitState,
      required: true
    }
  },
  computed: {
    relativeApplied(){
      return this.ballotState.appliedWeight() / (this.ballotState.ballot.maximumVotes * this.ballotState.representedVoters) * 100
    },
    relativeMinimum(){
      return this.ballotState.effectiveMinimum() / (this.ballotState.ballot.maximumVotes * this.ballotState.representedVoters) * 100
    },
    relativeBlank(){
      return this.ballotState.blankSelected / (this.ballotState.representedVoters) * 100
    }
  }
}
</script>

<style scoped>
.minimum-indicator{
  position: absolute;
  top: -8px;
  border: transparent 8px solid;
  border-top-color: #000A;
  background-clip: content-box;
  box-sizing: content-box;
  transition: left ease 250ms;
}

.hover-fatten {
  transition: transform 250ms ease, width 0.6s ease;
  transform-origin: bottom;
}

.hover-fatten:hover {
  transform: scaleY(1.6);
}
</style>