<template>
  <div class="option p-3 mb-3 border" style="padding-right: 1rem !important;">
    <div class="d-flex align-items-center">
      <img v-if="option.image" :src="option.image" class="img-fluid mr-3" style="max-height: 4em; max-width: 4em;">
      <span class="h5 flex-grow-1">{{optionTitle}}</span>
      <input class="option-number font-weight-bold ml-3 align-self-start"
            type="number"
            :class="{'checked': newValue > 0}"
            @input="validateWeight()"
            min="0" step="1"
            v-model.number="newValue">
    </div>
    <div v-if="optionDescription" v-html="optionDescription" class="mt-2"></div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex"

  export default {
    props: {
      option: {
        type: Object,
        required: true
      },
      availableWeight: {
        type: Number,
        default: 0
      },
      voterWeight: {
        type: Number,
        default: 0
      },
      maximumVotes: {
        type: Number,
        default: 1
      },
      value: {
        type: Array,
        required: true
      },
    },
    data(){
      return {
        currentValue: null,
        newValue: null
      }
    },

    computed: {
      ...mapGetters(['firstAvailableLocale']),
      optionTitle(){
        return this.option.title[this.firstAvailableLocale] || Object.values(this.option.title)[0] || ""
      },
      optionDescription(){
        return this.option.description[this.firstAvailableLocale] || ""
      },
      checked(){
        return this.selected.some(option => option.handle === this.option.handle)
      },
      selected: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      },
    },
    methods: {
      toggleSelection(){
        if((this.newValue === 0 || this.newValue === "" || this.newValue.toString().match(/[\-\e\D]/))){
          this.newValue = 1
          this.newValue = null
          if(this.checked){
            let option = this.selected.find(option => option.handle === this.option.handle)
            this.selected.splice(this.selected.indexOf(option), 1)
          }
        } else if(!this.checked) {
          this.selected.push({handle: this.option.handle, weight: this.currentValue})
        } else {
          this.selected.find(option => option.handle === this.option.handle).weight = this.currentValue
        }

      },
      validateWeight(){
        if(this.maximumVotes > 1 && this.newValue > this.voterWeight){
          this.newValue = this.voterWeight
        }
        if(this.newValue-this.currentValue > this.availableWeight){
          this.newValue = this.currentValue + this.availableWeight
        }
        this.currentValue = this.newValue
        this.toggleSelection()
      }
    },
    mounted(){
      if(this.checked){
        this.newValue = this.selected.find(option => option.handle === this.option.handle).weight
      }
    }
  }
</script>
