<template>
  <div class="mb-3 hover-actions position-relative">
    <EditForm v-if="editForm" :item="item" @closeForm="editForm = false" />
    <div v-else  style="border: 1px solid #9B9F9B; border-radius: 5px" :class="{'pulse-shadow': unread}" @focus="unread ? markPostRead(item.id) : null" @mouseover="unread ? markPostRead(item.id) : null">
      <div  v-if="itemOwner || !itemDeleted || didDeleteItem">
        <div class="p-3 rounded-top" :class="messageColor">
          <i v-if="itemDeleted" class="fas fa-eye-slash mb-2 float-right" v-tooltip="$t('js.tooltips.only_visible_to_you')"></i>
          <h5><i class="fas fa-file-signature"></i> {{  item.target_slide.deleted ? $t('js.sidebar.general.deleted', {resource: $t('js.sidebar.general.resource.slide')}) : item.target_slide.title[firstAvailableLocale] }}</h5>
          <span>{{$t('js.sidebar.general.by')}} {{ item.owner.deleted ? $t('js.sidebar.general.deleted', {resource: $t(`js.sidebar.general.resource.${item.owner.type}`)}) : item.owner.name }}</span>
        </div>
        <div class="p-3 text-white" :class="itemDeleted ? 'text-white-50' : 'text-white'" style="white-space: pre-wrap; overflow-wrap: break-word;">{{ item.message }}</div>
      </div>
      <div v-if="itemDeleted" class="d-flex align-items-center justify-content-center p-3 small text-white-50">
        <i class="pr-3 fas fa-trash-alt"></i>
        <span>{{ $t('js.sidebar.amendments.deleted_by') }} {{ $t(`js.sidebar.general.deleted_by.${item.deleted_by.type}`) }}</span>
      </div>
      <PostActions :post="item" :editing="editForm" @prepareEdit="editForm = true" @cancelEdit="editForm = false"/>
    </div>
    <div class="d-flex justify-content-between small text-white-50">
      <span>{{ item.owner.deleted ? $t('js.sidebar.general.deleted', {resource: $t(`js.sidebar.general.resource.${item.owner.type}`)}) : item.owner.name }}</span>
      <span v-if="item.edited && !itemDeleted">{{ $t('js.sidebar.general.edited') }}</span>
      <span>{{ item.created_at }}</span>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex"
import PostActions from "../PostActions"
import EditForm from "./EditForm"
export default {
  name: "Amendment",
  components: {EditForm, PostActions},
  props: {
    item: Object
  },
  data(){
    return {
      editForm: false,
      read: false
    }
  },
  computed: {
    ...mapState(['chatUser', 'slides']),
    ...mapGetters(['firstAvailableLocale']),
    unread(){
      return this.item.unread
    },
    itemOwner(){
      return this.item.owner.id === this.chatUser.id && this.item.owner.type === this.chatUser.type
    },
    ballotSlides(){
      return this.slides.filter(s => s.type === 'Slides::BallotSlide')
    },
    itemDeleted(){
      return this.item.deleted_by && this.item.deleted_by.id && this.item.deleted_by.type
    },
    messageColor(){
      return this.itemDeleted ? 'text-white-50 bg-light-transparent' : 'bg-theme'
    },
    didDeleteItem(){
      return this.item.deleted_by.id && this.item.deleted_by.type && this.chatUser.type === this.item.deleted_by.type && this.item.deleted_by.id === this.chatUser.id
    }
  },
  methods: {
    ...mapMutations(['markPostRead']),
  }
}
</script>