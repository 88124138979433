<template>
    <div class="d-flex h-100 flex-column overflow-auto">
        <TabHeader :header="$t('js.sidebar.headers.locales')"/>
        <div class="h-100 p-3 bg-blur-dark">
            <button class="w-100 btn btn-theme-transparent mb-2 text-white" :class="{active: locale === $i18n.locale}" style="background: rgba(0,0,0,0.5)" href="#"
                    @click="setLocale(locale)" v-for="locale in availableLocales">{{ $t('js.language_name', locale) }}
            </button>
        </div>

    </div>
</template>

<script>
    import TabHeader from "../frontend/voting/sidebar/TabHeader"
    import {mapMutations, mapState} from 'vuex'
    export default {
        name: "LocaleTab",
        components: {TabHeader},
        computed: {
            ...mapState(['locales']),
            availableLocales(){
                return this.locales;
            },
            currentLocale(){
                return this.$i18n.locale;
            }
        },
        methods:{
            ...mapMutations(['setLocale'])
        }
    }
</script>