<template>
  <div class="d-flex flex-column align-items-center bg-blur-darker text-white px-1 py-3"
       v-tooltip:left="$t('js.sidebar.general.signed_in_as', { name: voter.name })">
    <i class="fas fa-id-card"></i>
    <small v-text="voter.name" class="text-center" style="word-break: break-word; hyphens: auto"></small>
  </div>
</template>
<script>
export default {
  name: 'VoterIdentifier',
  props: {
    voter: {}
  }
}
</script>