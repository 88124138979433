<template>
    <div class="slide w-100 p-0 m-0">
            <div class="container d-flex flex-column p-0 px-md-5 pt-sm-3 pt-md-5 pb-5">
                <div class="card border-0 rounded-0 shadow">
                    <div v-if="showHeader" class="card-header d-flex flex-column flex-column-reverse flex-md-row align-items-stretch bg-theme rounded-0 border-bottom align-items-md-center p-0">
                        <div v-if="election.theme.logo" class="bg-light p-3 align-self-stretch d-flex align-items-center">
                            <img :src="election.theme.logo" style="max-height:50px;max-width:100%" />
                        </div>
                        <div class="d-flex flex-grow-1 align-items-center justify-content-between p-3">
                            <h3 class="mb-0">{{election.title[firstAvailableLocale]}}</h3>
                        </div>

                    </div>

                    <div class="card-body p-3 px-md-5 pt-lg-5 d-flex flex-column">
                        <template v-if="slide.type === 'Slides::PresentationSlide'">
                            <template v-if="slide.layout === 'header_with_text'">
                                <header class="h1 text-center">{{title}}</header>
                                <div class="lead" v-html="description"></div>
                            </template>
                            <template v-else-if="slide.layout === 'header_with_image'">
                                <header class="h1 text-center">{{title}}</header>
                                <img class="img-fluid align-self-center" :src="image"/>
                            </template>
                            <div v-else-if="slide.layout === 'single_image'" class="text-center">
                                <img class="img-fluid align-self-center" :src="image"/>
                            </div>
                        </template>
                        <template v-else-if="slide.type === 'Slides::BallotSlide'">
                            <StickyAlerts>
                                <template #before>
                                    <header class="h1 text-center">{{title}}</header>
                                    <div class="lead mb-3" v-html="description"></div>
                                    <Ballot :ballot="slide"/>
                                </template>
                            </StickyAlerts>
                        </template>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex'
    import LocaleSwitcher from "../shared/LocaleSwitcher";
    import SlideIndicators from "../../shared/SlideIndicators"
    import Ballot from './Ballot'
    import StickyAlerts from './StickyAlerts'

    export default {
        components: {
            StickyAlerts,
            LocaleSwitcher,
            Ballot,
            SlideIndicators
        },
        name: "Slide",
        props: {
            active: {
                type: Boolean,
                required: false,
                default: false
            },
            slide: {
                type: Object,
                required: false,
                default: ()=>{
                    return {type: 'none'}
                }
            },
          showHeader: {
              type: Boolean,
            default: true
          }
        },
        computed: {
            ...mapState(['slideOffset', 'election']),
            ...mapGetters(['firstAvailableLocale']),
            title(){
                return this.slide.title[this.firstAvailableLocale];
            },
            description(){
                return this.slide.description[this.firstAvailableLocale];
            },
            image(){
                return this.slide.image;
            }
        }

    }
</script>
