<template>
  <button class="w-100 btn text-center position-relative rounded-0 py-3 overflow-hidden" :class="{'active': isActive, [color]: true}" style="line-height: 1em" @click="onClick">
    <i class="fas" :class="icon"></i><br>
    <span v-if="unreadCount" class="badge badge-pill bg-theme-danger position-absolute" style="top: 15%; left: 60%">{{ unreadCount }}</span>
    <span class="circle pulse-button" v-if="pulse"></span>
    <span class="small">{{ text }}</span>
  </button>
</template>
<script>
  export default {
    name: 'SideBarButton',
    props: {
      icon: {
        type: String,
        default: ''
      },
      unread: 0,
      text: {
        type: String,
        default: ''
      },
      isActive: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'btn-theme'
      },
      pulse: false
    },
    computed: {
      unreadCount(){
        let count = null
        count = this.unread > 0 ? this.unread : null
        if(this.unread > 999) count = 999;
        return count
      }
    },
    methods:{
      onClick(){
        this.$emit('click')
      }
    }
  }
</script>