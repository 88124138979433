<template>
  <div v-if="ruleSet === 'split'" class="option p-3 mb-3 border mt-5" style="padding-right: 1rem !important;">
    <div class="d-flex align-items-center">
      <span class="h5 flex-grow-1">{{option.title[firstAvailableLocale]}}</span>
      <input class="option-number font-weight-bold ml-3 align-self-start"
             type="number"
             :class="{'checked': value > 0}"
             @input="validateNumberInput"
             min="0" step="1" :max="state.representedVoters"
             v-model.number="newValue">
    </div>
    <div class="text-muted">{{ $t('js.ballot.blank_option_split_desc') }}</div>
  </div>
  <OptionBoolean v-else :option="option" :ranked="false" v-model="selectedArray"></OptionBoolean>
</template>

<script>
import OptionBoolean from "./OptionBoolean";
import OptionNumber from "./OptionNumber";
import {mapGetters, mapState} from "vuex";
export default {
  name: "OptionBlank",
  components: {OptionNumber, OptionBoolean},
  props: {
    ruleSet: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    state: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      selectedArray: this.value === 0 ? [] : ['blank'],
      newValue: 0
    }
  },
  computed:{
    ...mapState(['locales']),
    ...mapGetters(['firstAvailableLocale']),
    option() {
      return {
        title: this.locales.reduce((localString, locale) => {
          localString[locale] = this.$t(this.ruleSet === 'split' ? 'js.standard_option.blank_ballots' : 'js.standard_option.blank', locale)
          return localString
        }, {}),
        description: this.locales.reduce((localString, locale) => {
          localString[locale] = ""
          return localString
        }, {}),
        handle: 'blank',
        exclusive: true
      }
    },
    blankSelected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
  },
  methods: {
    validateNumberInput(){
      let usedBallots = Math.ceil(this.state.appliedWeight() / this.state.ballot.maximumVotes)
      if((usedBallots + this.newValue) > this.state.representedVoters){
        this.newValue = this.state.representedVoters - usedBallots;
      }
      if(this.newValue < 0 || !Number.isInteger(this.newValue)) {
        this.newValue = 0
      }
      this.blankSelected = this.newValue
    }
  },
  watch: {
    selectedArray: function() {
      this.$emit('input', this.selectedArray.length);
    }
  }

}
</script>
