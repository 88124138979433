<template>
  <div v-if="isEnabled" class="d-flex flex-column text-center p-2 text-white" style="background: rgba(30,30,30, 0.8)" :key="currentLocale">
    <div v-for="count in shownCounts" class="d-flex align-items-center mb-1" v-if="count.condition || count.condition === undefined" v-tooltip:auto="$t(count.tooltip)">
      <i class="fas w-25" :class="`fas fa-${count.icon}`"></i><TweenedCount class="w-75" :count="count.count"/>
    </div>
  </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex'
    import TweenedCount from "./TweenedCount";

    export default {
        name: "VoterCounts",
      components: {TweenedCount},
      computed: {
          ...mapState(['election', 'voterCounts', 'currentLocale']),
          ...mapGetters(['activeSlide']),
          ballotOpen() {
            return this.activeSlide !== undefined && this.activeSlide.type === 'Slides::BallotSlide' && ['open', 'countdown'].includes(this.activeSlide.state)
          },
          isEnabled() {
            return this.election.voterCountsConfig && this.election.voterCountsConfig.enabled && this.shownCounts.length > 0
          },
          shownCounts() {
            return this.election.voterCountsConfig && this.election.voterCountsConfig.shownCounts.map(c => this.countInfo(c))
          }
        },
        methods: {
          countInfo(key){
            switch(key){
              case 'active_eligibles':
                return {icon: 'person-booth', count: this.voterCounts.activeEligibles, tooltip: 'js.voter_counts.active_eligibles', condition: this.ballotOpen}
              case 'active_eligibles_weight':
                return {icon: 'weight-hanging', count: this.voterCounts.activeEligiblesWeight, tooltip: 'js.voter_counts.active_eligibles_weight', condition: this.ballotOpen}
              case 'active_voters':
                return {icon: 'eye', count: this.voterCounts.activeVoters, tooltip: 'js.voter_counts.active_voters'}
              case 'total_voters':
                return {icon: 'users', count: this.voterCounts.totalVoters, tooltip: 'js.voter_counts.total_voters'}
            }
          }
        }
    }
</script>