<template>
  <div class="d-flex h-100 flex-column">
    <TabHeader :header="$t('js.sidebar.headers.comments')"/>
    <div v-if="posts.length < 1" class="p-3 bg-blur-dark flex-grow-1" >
      <div class="text-white-50 font-italic text-center">
        {{ $t('js.sidebar.comments.no_comments') }}
      </div>
    </div>
    <SectionList v-else :items="posts" :group-by="(post) => groupBy(post)" class="bg-blur-dark flex-grow-1" ref="comment_list">
      <template v-slot:headerTemplate="{ groupKey }">
        <header class="h-4" style="border-bottom: 2px solid #9B9F9B">
          <span class="text-white-50">
            {{$t('js.slide.one')}}: {{ slides.get(Number.parseInt(groupKey)).title[firstAvailableLocale] }}
          </span>
        </header>
      </template>
      <template v-slot:itemHeaderTemplate="{ groupKey }">
        <header class="mt-2 mb-3 py-2" style="border-bottom: 1px solid #9B9F9B">
          <span class="text-white-50">
            {{$t('js.slide.one')}}: {{ slides.get(Number.parseInt(groupKey)).title[firstAvailableLocale] }}
          </span>
        </header>
      </template>
      <template v-slot:itemTemplate="{ item }">
        <Comment v-if="item.type === 'Comment'" :item="item" />
        <div v-else-if="item.type === 'Amendment'" class="d-flex align-items-center justify-content-center mb-3 text-white-50">
          <i class="p-3 fas fa-file-signature"></i>
          <span class="small" v-html="$t('js.sidebar.comments.amendment_by_created_for_html', {name: item.owner.deleted ? $t('js.sidebar.general.deleted', {resource: $t(`js.sidebar.general.resource.${item.owner.type}`)}) : item.owner.name, ballot: item.target_slide.deleted ? $t('js.sidebar.general.deleted', {resource: $t('js.sidebar.general.resource.slide')}) : item.target_slide.title[firstAvailableLocale]})"></span>
        </div>
        <div v-else-if="item.type === 'Candidacy'" class="d-flex align-items-center justify-content-center mb-3 text-white-50">
          <i class="p-3 fas fa-user"></i>
          <span class="small" v-html="$t('js.sidebar.comments.candidacy_by_created_for_html', {name: item.owner.deleted ? $t('js.sidebar.general.deleted', {resource: $t(`js.sidebar.general.resource.${item.owner.type}`)}) : item.owner.name, ballot: item.target_slide.deleted ? $t('js.sidebar.general.deleted', {resource: $t('js.sidebar.general.resource.slide')}) : item.target_slide.title[firstAvailableLocale]})"></span>
        </div>
      </template>
    </SectionList>
    <div class="p-3 bg-blur-darker">
      <form @submit.prevent="validateForm()">
        <div class="input-group">
          <input v-model="message" type="text" class="form-control" :placeholder="$t('js.sidebar.comments.comment_placeholder')">
          <div class="input-group-append">
            <button class="btn btn-theme" :disabled="submitting" @click.prevent="validateForm()"><i class="fas fa-paper-plane"></i></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
  import SectionList from "../../../shared/SectionList";
  import TabHeader from "./TabHeader"
  import Comment from "../../../shared/posts/Comment"

  export default {
    name: 'Comments',
    components: {Comment, TabHeader, SectionList},
    data(){
      return {
        message: "",
        response: "",
        scrolled: false,
        submitting: false
      }
    },
    computed: {
      ...mapState(['election', 'voting', 'posts', 'postsUrl', 'chatUser']),
      ...mapGetters(['activeSlide', 'firstAvailableLocale']),
      slides() {
        let fake_slide = {}
        fake_slide[''+this.$i18n.locale] = this.$t('js.slide_show.no_slide_active')
        return this.posts.reduce(function (headers, post) {
          if(post.slide){
            headers.set(post.slide.id, post.slide)
          } else {
            headers.set(0, {title: fake_slide })
          }
          return headers
        } ,new Map())
      }
    },
    methods: {
      ...mapMutations(['markAllPostsRead']),
      ...mapActions(['updateStatus', 'submitPost']),
      validateForm(){
        if(this.submitting) return;
        if(this.message.trim().length > 0){
          this.submitting = true
          let post = {
            post: {
              type: 'Posts::Comment',
              message: this.message.trim()
            }
          }
          this.submitPost(post).then(res => {
            if(res.status === 200){
              this.message = ""
              this.$refs.comment_list.scrolled = false
              this.$refs.comment_list.scrollChatBottom()
            }
            this.response = res.message
          }).finally(()=>{
            this.submitting = false
          })
        }
      },
      groupBy(post){
        let group = null
        if(post.slide) {
          group = post.slide.id
        } else {
          group = 0
        }
        return group
      }
    },
    mounted(){
      this.markAllPostsRead('Comment')
    }
  }
</script>
