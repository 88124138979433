<template>
  <div id="slide-body" v-if="activeSlide" class="d-flex flex-column">
    <div class="flex-grow-1 w-100 position-relative">
      <transition name="slide" :enter-class="slideOffset < 0 ?'slide-right-position':'slide-left-position'"
                  :leave-to-class="slideOffset < 0 ?'slide-left-position':'slide-right-position'">
        <Slide v-if="slide.id === activeSlide.id" v-for="slide in slides" :slide="slide"
               :active="slide.id === activeSlide.id" :showHeader="showHeader" :key="slide.id"/>
      </transition>
    </div>
    <div class="mt-auto flex-shrink-1" :class="{'fixed-bottom': changingSlide}" ref="indicator">
      <SlideIndicators/>
      <transition name="fade">
        <div v-if="changingSlide" style="box-shadow: rgba(0, 0, 0, 0.6) 0 10px 50px 30px;" ></div>
      </transition>
    </div>
  </div>
  <div v-else class="col d-flex flex-grow-1 justify-content-center align-items-center">
    <div class="text-center text-white p-2">
      <h3>{{ $t('js.slide_show.no_slide_active') }}</h3>
      <p>{{ $t('js.slide_show.presentation_not_started') }}</p>
    </div>
  </div>
</template>
<script>
    import Slide from './Slide'
    import {mapState, mapGetters} from "vuex"
    import SlideIndicators from "../../shared/SlideIndicators";
    import SideBar from './sidebar/SideBar'

    export default {
        name: 'SlideShow',
        components: {Slide, SlideIndicators, SideBar},
        data(){
          return {
            changingSlide: false
          }
        },
      props: {
        showHeader: {
            type: Boolean,
            default: true
          }
      },
        computed: {
            ...mapState(['slides','slideOffset']),
            ...mapGetters(['activeSlide']),
        },
        watch:{
            activeSlide: function(newVal, oldVal){
                if(newVal === undefined || newVal === null) return
                if(oldVal === undefined || oldVal === null || oldVal.id === newVal.id) return
                // This makes the slide indicator stay a bottom of screen
                // when animating a scroll back to the top between slides with a lot of text (height)
                let width = null
                let indicatorElement = this.$refs.indicator
                if(indicatorElement) width = indicatorElement.offsetWidth
                if(width) indicatorElement.style.maxWidth = width+"px"
                this.changingSlide = true

                let slideBody = $('#slide-body')
                slideBody.stop(true, false).animate({scrollTop:0}, 1000);
                setTimeout(() => {this.changingSlide = false; indicatorElement.style.maxWidth = null}, 1000)

                slideBody.on("mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function(){
                    slideBody.stop(true, false);
                });
            }
        }
    }

</script>
