<template>
    <div>
        <ConnectionIndicator/>
        <LoggedOutModal/>
        <transition name="fade" mode="out-in">
            <Election v-if="voterSessionUuid" key="election" />
            <div class="min-vh-100 d-flex flex-column justify-content-between" v-else key="login">
                <div></div>
                <div class="container p-4">
                    <Authenticate/>
                </div>
                <div class="dark-blur px-sm-4 py-2 mt-5">
                    <div class="container d-flex flex-wrap align-items-center justify-content-between">
                      <div v-if="partnerBranding.logo">
                        <a :href="partnerBranding.link" target="_blank">
                          <img :src="partnerBranding.logo" style="height: 50px"/>
                        </a>
                      </div>
                      <a class="text-decoration-none text-white float-sm-right" href="https://www.assemblyvoting.com" target="_blank">
                          <span class="small">{{ $t('js.authenticate.technology_by') }}</span>
                          <img  alt="Assembly Voting" :src="require('images/av-logo-horizontal-white.svg')" style="line-height: 0; height: 25px"/>
                      </a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Authenticate from './Authenticate'
    import Election from './Election'
    import ConnectionIndicator from "../shared/ConnectionIndicator";
    import LoggedOutModal from "./modals/LoggedOutModal";

    export default {
        components: {
            ConnectionIndicator,
            Authenticate,
            Election,
            LoggedOutModal
        },
        computed: {
            ...mapState({
              voterSessionUuid: state => state.voting.voterSessionUuid,
              election: state => state.election,
              partnerBranding: state => state.partnerBranding
            }),
        },
        methods: {
            ...mapActions(['checkTime'])
        },
        created() {
            this.checkTime()
        }
    }
</script>