export default class RankedState {
    constructor(ballot) {
        this.ballot = ballot;
        this.selected = [];
        if(this.ballot.blankOption === 'implicit'){
            Object.defineProperty(this, 'blankSelected', { get: this.calcBlank });
        } else {
            this.blankSelected = 0;
        }
        this.errors = this.validate(); // vue automatically recalculates this when selected changes
    }

    validate(){ // allow for blank vote? should that be an option on its own?
        let errors = [];

        if(this.selected.length > this.ballot.maximumVotes){
            errors.push({path:'js.error.ranked.too_many_crosses', values:{maximum: this.ballot.maximumVotes}})
        }

        if(this.selected.length > 0 && this.blankSelected > 0){
            errors.push({path:'js.error.ranked.blank_combined_with_other_options'})
        }

        return errors;
    }

    // Calculate blank when selected changes if ballot has implicit blank option
    calcBlank(){
        return this.selected.length === 0 ? 1 : 0
    }

    ///
    // we are good if there are no errors and the minimum required votes are selected OR a single exclusive option is selected (like blank)
    complete(){
        if (this.validate().length > 0) {
            return false;
        } else {
            return this.selected.length >= this.ballot.minimumVotes || this.blankSelected > 0;
        }
    }

    extract(){
        return {
            ballot_id: this.ballot.id,
            votes: this.blankSelected === 0 ? this.selected.map((handle, index) => {
                return {rank: index + 1, handle}
            }) : [{handle: 'blank', rank: 1}]
        }
    }
}