<template>
  <div class="text-dark">
    <div :class="listClass">
      <Option v-for="(row, index) in rows" :class="optionClass"
              :key="row.option.handle"
              :option="row.option"
              :showPercentage="ballot.showResultPercentage"
              :votes="row.count"
              :total="totalCount"
              :elected="elected.includes(row.option.handle)"
              :tied="tied.includes(row.option.handle)"
      />
      <Option v-if="blankOption"
              class="col"
              :key="'blank'"
              :showPercentage="ballot.showResultPercentage && !ballot.disregardBlankVotes"
              :option="blankOption.option"
              :votes="blankOption.count"
              :total="totalCount"
              :elected="false"
              :tied="false"
      />
      <NormalResultChart :result="result" :ballot="ballot" :theme="theme"/>
    </div>
  </div>
</template>
<script>
import Option from "../frontend/shared/Option"
import {mapGetters, mapState} from 'vuex'
import NormalResultChart from "./NormalResultChart";

export default {
  name: "NormalSummary",
  components: {NormalResultChart, Option},
  props: {
    result: Object,
    ballot: Object,
    theme: String
  },
  computed: {
    ...mapState(['locales']),
    ...mapGetters(['firstAvailableLocale']),
    sortedCounts(){
      return this.resultData.optionSortedCounts
    },
    counts(){
      return this.resultData.optionCounts
    },
    rows(){
      if(this.sortedCounts){
        return this.sortedCounts.map(option => {
            return { option: this.ballot.options.find(o => o.handle.toString() === option[0].toString()), count: option[1] }
        })
      } else {
        return []
      }

    },
    blankOption(){
      if(this.ballot.blankOption !== 'disabled') {
        return { option: {
            title: this.locales.reduce((localString, locale) => {
              localString[locale] = this.$t('js.standard_option.blank', locale)
              return localString
            }, {}),
            description: this.locales.reduce((localString, locale) => {
              localString[locale] = ""
              return localString
            }, {}),
            handle: 'blank',
            exclusive: true
          }, count: this.counts['blank'] }
      } else {
        return null
      }
    },
    elected(){
      return this.resultData.elected
    },
    tied(){
      return this.resultData.tied || []
    },
    totalCount() {
      if (this.ballot.disregardBlankVotes) {
        return Object.entries(this.counts)
          .filter(([reference, _count]) => reference.toString() !== "blank")
          .reduce((sum, [_reference, count]) => sum + count, 0);
      } else if (this.ballot.consensusRequired && this.ballot.countUnvoicedVotesAsBlank){
        return this.ballot.disregardWeight
          ? this.result.voterCounts.activeEligiblesOrVoted
          : this.result.voterCounts.activeEligiblesOrVotedWeight;
      } else {
        return Object.values(this.counts).reduce((sum, count) => sum + count, 0)
      }
    },
    resultData(){
      return this.result.resultData
    },
    listClass(){
      if(this.rows.length <= 5){
        return 'option-list'
      }else if(this.rows.length > 5 && this.rows.length <= 10){
        return 'row p-3'
      }else{
        return 'row p-3'
      }
    },
    optionClass(){
      if(this.rows.length <= 5){
        return ''
      }else if(this.rows.length > 5 && this.rows.length <= 10){
        return 'col-md-6 col-sm-12'
      }else{
        return 'col-md-6 col-xl-4 m-0 p-2'
      }
    }
  }
}
</script>