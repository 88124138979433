<template>
  <div class="embed-container">
    <iframe :src="embedUrl" allow="autoplay" webkitAllowFullScreen mozallowfullscreen allowfullscreen />
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'Stream',
  computed: {
    ...mapState(['election']),
    embedUrl(){
      return this.election.streamUrl
    }
  }
}
</script>