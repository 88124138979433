<template>
  <span v-text="tweenedCount.toFixed(0)"></span>
</template>

<script>
export default {
name: "TweenedCount",
  props: {
    count: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 1000
    }
  },
  data() { return {
    tweenedCount: 0,
    start: 0.0,
    timerId: null,
    oldCount: 0
  } },
  methods: {
    interpolate() {
      this.start = Date.now()
      this.oldCount = this.tweenedCount
      if(this.timerId) clearInterval(this.timerId)
      this.timerId =
          setInterval(()=>{
            let t = Math.min((Date.now() - this.start) / this.duration, 1.0)
            this.tweenedCount = this.oldCount * (1-t) + this.count * t
            if(t === 1.0) {
              clearInterval(this.timerId)
              this.timerId = null
            }
          }, 1000/60)
    }
  },
  watch: {
    count: function(newCount) {
      this.interpolate()
    }
  },
  mounted() {
    this.interpolate()
  }
}
</script>