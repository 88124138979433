// overrides log function, muting it unless window.__DEBUG_MODE evaluates to a truthy value
const logOverrideFunction = (function(logFunction){
  if(process.env.NODE_ENV === 'development') window.__DEBUG_MODE = true
  return function(message, ...optionalParams){
    if(window.__DEBUG_MODE) {
      logFunction(message, ...optionalParams)
    }
  };
}(console.log));

//Then redefine the old console
console.log = logOverrideFunction;