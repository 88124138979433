<template>
  <div class="p-0 d-flex full-lg justify-content-end">
    <transition name="expand" mode="out-in">
      <div v-if="userModules.comments && visibleTab === 'Comment'" key="comments" class="full-lg">
        <Comments class="side-bar-tab " @showNav="showNav"  />
      </div>
      <div v-else-if="userModules.amendments && visibleTab === 'Amendment'" key="amendments" class="full-lg">
        <Amendments class="side-bar-tab" @showNav="showNav" />
      </div>
      <div v-else-if="userModules.candidacies && visibleTab === 'Candidacy'" key="candidacies" class="full-lg">
        <Candidacies class="side-bar-tab" @showNav="showNav"/>
      </div>
      <div v-else-if="visibleTab === 'Locales'" key="locales" class="full-lg">
        <LocaleTab class="side-bar-tab" @showNav="showNav"/>
      </div>
      <div v-else-if="visibleTab === 'Presentation'" key="presentation" class="d-block d-lg-none full-lg">
        <SlideTab @showNav="showNav" />
      </div>
    </transition>

    <div class="d-none d-lg-flex flex-column justify-content-between m-0 text-center bg-dark"
         style="position: relative; z-index: 2; min-width: 100px; max-width: 100px;">
      <div>
        <SideBarButton v-if="userModules.comments" @click="showTab('Comment')" :isActive="visibleTab === 'Comment'" :unread="unreadComments"
                       :text="$t('js.sidebar.headers.comments')" :icon="'fa-comment'"/>
        <SideBarButton v-if="userModules.amendments" @click="showTab('Amendment')" :isActive="visibleTab === 'Amendment'" :unread="unreadAmendments"
                       :text="$t('js.sidebar.headers.amendments')" :icon="'fa-file-signature'"/>
        <SideBarButton v-if="userModules.candidacies" @click="showTab('Candidacy')" :isActive="visibleTab === 'Candidacy'" :unread="unreadCandidacies"
                       :text="$t('js.sidebar.headers.candidacies')" :icon="'fa-user'"/>
        <SideBarButton data-toggle="modal" data-target="#highlightModal" :isActive="activeHighlight != null" :color="activeHighlight ? 'btn-success' : 'btn-secondary'"
                       :text="$t('js.sidebar.headers.highlight')" :icon="'fa-thumbtack'" :pulse="activeHighlight"/>
      </div>
      <div>
        <VoterCounts />
        <SideBarButton v-if="availableLocales.length > 1" @click="showTab('Locales')"
                       :text="$t('js.sidebar.headers.locales')" :icon="'fa-flag'" :color="'btn-secondary'"/>
        <SideBarButton @click="destroySession(false)"
                       :text="$t('js.actions.sign_out')" :icon="'fa-door-open'" :color="'btn-theme-danger'"/>
      </div>
    </div>

    <!-- Mobile navbar -->
    <transition name="expand" mode="out-in">
        <div v-show="navVisible" class="h-100 d-lg-none flex-column justify-content-between m-0 bg-dark position-absolute"
             style="z-index: 2; overflow-y: auto; display: flex; min-width: 200px; max-width: 200px;">

          <div>
            <div class="d-flex">
              <MSideBarButton class="text-white py-2" @click="navVisible = !navVisible"
                              :icon="'fa-times'" :color="'btn-link'" :text="'Menu'"/>
            </div>
            <MSideBarButton class="d-block d-lg-none" @click="showTab('Presentation')" :isActive="visibleTab === 'Presentation'"
                           :text="$t('js.sidebar.headers.presentation')" :icon="'fa-images'"/>
            <MSideBarButton v-if="userModules.comments" @click="showTab('Comment')" :isActive="visibleTab === 'Comment'" :unread="unreadComments"
                           :text="$t('js.sidebar.headers.comments')" :icon="'fa-comment'"/>
            <MSideBarButton v-if="userModules.amendments"@click="showTab('Amendment')" :isActive="visibleTab === 'Amendment'" :unread="unreadAmendments"
                           :text="$t('js.sidebar.headers.amendments')" :icon="'fa-file-signature'"/>
            <MSideBarButton v-if="userModules.candidacies" @click="showTab('Candidacy')" :isActive="visibleTab === 'Candidacy'" :unread="unreadCandidacies"
                           :text="$t('js.sidebar.headers.candidacies')" :icon="'fa-user'"/>
            <MSideBarButton data-toggle="modal" data-target="#highlightModal" :isActive="activeHighlight != null" :color="activeHighlight ? 'btn-success' : 'btn-secondary'"
                           :text="$t('js.sidebar.headers.highlight')" :icon="'fa-thumbtack'" :pulse="activeHighlight"/>
          </div>
          <div>
            <VoterCounts class="px-5" />
            <MSideBarButton v-if="availableLocales.length > 1" @click="showTab('Locales')"
                           :text="$t('js.sidebar.headers.locales')" :icon="'fa-flag'" :color="'btn-secondary'"/>
            <MSideBarButton @click="destroySession(false)"
                           :text="$t('js.actions.sign_out')" :icon="'fa-door-open'" :color="'btn-theme-danger'"/>
          </div>
        </div>
    </transition>
    <div v-show="navVisible" @click="navVisible = !navVisible" style="position: fixed; top: 0; bottom: 0; left: 0; right:0;" class="bg-blur-dark">
      <!-- this is a cover up -->
    </div>
    <HighlightModal />
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
  import Comments from "./Comments"
  import Candidacies from "./Candidacies"
  import Amendments from "./Amendments"
  import LocaleTab from "../../../shared/LocaleTab"
  import SlideTab from "./SlideTab"
  import SideBarButton from "./SideBarButton";
  import VoterCounts from "../../../shared/VoterCounts"
  import MSideBarButton from "./MSideBarButton"
  import HighlightModal from "../../shared/HighlightModal"

  export default {
    name: 'SideBar',
    components: {HighlightModal, MSideBarButton, VoterCounts, SideBarButton, SlideTab, LocaleTab, Amendments, Candidacies, Comments},
    data(){
      return {
        editPostId: null,
        navVisible: false
      }
    },
    computed: {
      ...mapState(['election', 'voting', 'posts', 'activeHighlight', 'visibleTab']),
      ...mapGetters(['activeSlide']),
      availableLocales(){
        return this.$i18n.availableLocales;
      },
      userModules(){
        return this.election.userModules
      },
      unreadComments(){
        if(this.visibleTab === 'Comment') return 0
        return this.posts.filter(p => p.type === 'Comment' && p.unread).length
      },
      unreadAmendments(){
        return this.posts.filter(p => p.type === 'Amendment' && p.unread).length
      },
      unreadCandidacies(){
        return this.posts.filter(p => p.type === 'Candidacy' && p.unread).length
      }
    },
    methods: {
      ...mapActions(['updateStatus','destroySession']),
      ...mapMutations(['setVisibleTab']),
      showTab(tab){
        this.navVisible = false
        if( tab === this.visibleTab){
          this.setVisibleTab('Presentation')
        } else {
          this.setVisibleTab(tab)
        }
      },
      showNav(){
        this.navVisible = !this.navVisible
      },
    }
  }
</script>
