<template>
    <div class="d-flex h-100">
        <div id="m-slide-body" v-if="activeSlide" class="d-flex flex-column w-100 pretty-scroll" style="overflow-y: auto; overflow-x: hidden">
            <div class="flex-grow-1 w-100 position-relative">
              <div class="p-0 px-md-5 pt-sm-3 container">
                <div class="embed-container">
                  <iframe src='https://www.youtube.com/embed/QILiHiTD3uc' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowfullscreen></iframe>
                </div>
              </div>
                <transition name="slide" :enter-class="slideOffset < 0 ?'slide-right-position':'slide-left-position'"
                            :leave-to-class="slideOffset < 0 ?'slide-left-position':'slide-right-position'">
                    <Slide v-if="slide.id === activeSlide.id" v-for="slide in slides" :slide="slide" :active="slide.id === activeSlide.id" :key="slide.id"/>
                </transition>
            </div>
          <div class="mt-auto flex-shrink-1" :class="{'fixed-bottom': changingSlide}" ref="indicator">
            <SlideIndicators/>
            <transition name="fade">
              <div v-if="changingSlide" style="box-shadow: rgba(0, 0, 0, 0.6) 0px 10px 50px 30px;" ></div>
            </transition>
          </div>
        </div>
        <div v-else class="col min-vh-100" style="background: rgba(0,0,0,0.5)">
            <button class="btn d-lg-none text-contrast float-right m-3" type="button" @click.prevent="$emit('showNav')">
                <i class="fas fa-bars"></i>
            </button>
            <div class="d-flex vh-100 justify-content-center align-items-center">
                <div class="text-center text-white p-2">
                    <h3>{{ $t('js.slide_show.no_slide_active') }}</h3>
                    <p>{{ $t('js.slide_show.presentation_not_started') }}</p>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import Slide from "../Slide"
    import SlideIndicators from "../../../shared/SlideIndicators"
    import {mapGetters, mapState} from "vuex"

    export default {
        name: "SlideTab",
        components: {SlideIndicators, Slide},
        computed: {
            ...mapState(['slides', 'slideOffset']),
            ...mapGetters(['activeSlide']),
        },
        data(){
          return {
            changingSlide: false
          }
        },
        watch:{
          activeSlide: function(newVal, oldVal){
              // This makes the slide indicator stay a bottom of screen
              // when animating a scroll back to the top between slides with a lot of text (height)
              if(oldVal === undefined || oldVal === null ) return
              $('#m-slide-body').stop(true, false);
              let width = null
              let indicatorElement = this.$refs.indicator

              if(indicatorElement) width = indicatorElement.offsetWidth
              if(width) indicatorElement.style.maxWidth = width+"px"
              this.changingSlide = true

              $('#m-slide-body').animate({scrollTop:0}, 1000);
              setTimeout(() => {this.changingSlide = false; indicatorElement.style.maxWidth = null}, 1100)
            }
        },
        methods: {
          stopAnimation(){
            $('#m-slide-body').stop(true, false)
          }
        }
    }
</script>