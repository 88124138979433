<template>
  <div>
    <div class="overflow-auto bg-white">
      <table class="table" id="ranked_summary_table">
        <thead class="thead-light">
        <tr>
          <th>{{ $t('js.ranked_summary.position') }}</th>
          <th>{{ $t('js.ranked_summary.candidate') }}</th>
          <th class="text-center" v-for="(round, index) in rounds">{{
              $t('js.ranked_summary.round_n', {n: index + 1})
            }}
          </th>
        </tr>
        </thead>
        <tbody>
          <tr :class="{'table-success': isElected(option), 'table-warning': isTied(option)}" v-for="(option, optionIndex) in sortedOptions">
            <td>{{ optionPosition(option) }}</td>
            <td>{{ option.title[firstAvailableLocale] }}</td>
            <td class="text-center" :class="{'font-weight-bold': number-1 === finalRound(option.handle)}" v-for="number in rounds.length">
              {{ countForRound(option.handle, number-1) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <p>{{ $t('js.ranked_summary.seats') }}: {{ ballot.seats }}</p>
      <p>{{ $t('js.ranked_summary.distribution_no') }}: {{ distributionNo }}</p>
      <p v-if="elected.length > 0">{{ $t('js.ranked_summary.elected') }}: <span>{{
          elected.map(option => optionLabel(option)).join(', ')
        }} </span></p>
      <p v-if="tied.length > 0">{{ $t('js.ranked_summary.tied') }}: <span>{{
          tied.map(option => optionLabel(option)).join(', ')
        }} </span></p>
    </div>
    <RankedResultChart :option-ranks="optionRanks" :rounds="rounds" :distribution-no="distributionNo"
                       :ballot-options="options" :chart-type="ballot.resultChart" :theme="theme"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import RankedResultChart from "./RankedResultChart"

export default {
  name: "RankedSummary",
  components: { RankedResultChart },
  props: {
    result: Object,
    ballot: Object,
    theme: String
  },
  computed: {
    ...mapGetters(['firstAvailableLocale']),
    optionRanks() {
      return this.result.resultData.optionRanks
    },
    rounds() {
      return this.result.resultData.rounds
    },
    elected() {
      return this.result.resultData.elected
    },
    tied() {
      return this.result.resultData.tied
    },
    distributionNo() {
      return this.result.resultData.distributionNo
    },
    options() {
      return this.ballot.options
    },
    notElected() {
      return this.options.filter(option => !this.elected.includes(option.handle))
          .sort((o1, o2) => this.rounds[this.rounds.length - 1].accumulatedCounts[o2.handle] - this.rounds[this.rounds.length - 1].accumulatedCounts[o1.handle])
    },
    sortedOptions() {
      return [...this.options].sort(this.compareOptions)
    },
  },
  methods: {
    optionLabel(optionHandle) {
      return this.options.filter(option => option.handle === optionHandle.toString())[0].title[this.firstAvailableLocale]
    },
    finalRound(handle) {
      const electedIndex = this.rounds.findIndex(round => round.elected.includes(handle))
      const lastIndex = this.rounds.length - 1
      return electedIndex !== -1 ? electedIndex : lastIndex
    },
    countForRound(handle, roundIndex){
      if(this.finalRound(handle) >= roundIndex || this.finalRound(handle) === -1)
        return this.rounds[roundIndex].accumulatedCounts[handle]
      else
        return ""
    },
    optionPosition(option) {
      if(this.elected.includes(option.handle))
        return this.sortedOptions.indexOf(option) + 1
      else
        return "-"
    },
    isElected(option) {
      return this.elected.includes(option.handle)
    },
    isTied(option) {
      if(this.isElected(option)) return false

      return this.tied.includes(option.handle)
    },
    compareOptions(option1, option2) {
      const relevantRound = Math.min(this.finalRound(option1.handle), this.finalRound(option2.handle))
      const sorting = this.countForRound(option1.handle, relevantRound) - this.countForRound(option2.handle, relevantRound)

      // If two options have the same sorting score and they aren't both elected,
      // sort the elected option before the unelected option
      if(sorting === 0 && this.isElected(option1) !== this.isElected(option2))
        return this.isElected(option1) ? -1 : 1
      else
        return -1 * sorting // Multiplied by -1 to sort in descending order
    },
  }
}
</script>
